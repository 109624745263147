import React from 'react';
import './Rules.css';
 
function Rules() {
    return (
        <>
            <div id="rules">
                <div className="heading">
                    <h1>Rules</h1>
                </div>
                <section className="listModule">
                    <ol className="listCustom listNumbered">
                        <li>
                            All rosters must be completed by cut-off time of  
                            <strong> 5:00 PM on April 20, 2024</strong>.
                            Incomplete rosters will compete with limited ability to
                            collect points. <strong>NO EXCEPTIONS!</strong>
                        </li>
 
                        <li>
                            All rosters are final at cut-off time. Make sure you check the
                            injury reports before selecting your roster. Injured players
                            cannot be replaced after playoff pool start date of
                            <strong> April 20, 2024</strong>.
                           
                        </li>
 
                        <li>
                            Rosters will consist of 20 players maximum with the following combinations:
                            Forwards: Min = 6 & Max = 12; Defenders: Min = 4 & Max = 8;
                            Goalies: Min = 2 & Max = 3.
                        </li>
 
                        <li>
                            Multiple team entries are permitted. A separate $25 entry fee is
                            required for each team entered.
                        </li>
 
                        <li>
                            Entry fee is $25 per team entered. All teams must be paid in full by
                            <strong> 5:00 PM on April 20, 2024 </strong>
                            to claim a prize. <strong>NO EXCEPTIONS!</strong>
                        </li>
 
                        <li>Points are cumulative throughout the playoffs.</li>
 
                        <li>
                            Prize distribution: At the conclusion of the Stanley Cup Final Series,
                            the Commissioners will announce a start date when prizes selection begins
                            so that poolies can prepare to receive the notifications.  Poolies will
                            receive an email notification in the order they finish in the pool
                            informing them they have won a prize.  In efforts to help expedite prize
                            distribution, poolies will have a maximum
                            <strong> 24 hours</strong> to select their prize before randomly being
                            awarded a prize.
                            <br/>
                            <strong>
                                NOTE: Emails can sometimes end up in JUNK/SPAM folders. Please ensure
                                <span className="text-info"> thecommish@fedhealthhockeypool.com</span> is added
                                to your safe sender's list so you receive the emails.
                            </strong>
                        </li>
 
                        <li>
                            In the event of a tie, Most Goals will determine pool winner.
                        </li>
 
                        <li>
                            Enable Receive Nightly Messages to receive regular communications
                            from the Commissioners.
                        </li>
 
                        <li>
                            Trash Talk is enabled as it adds a level of competitiveness. Please keep
                            messages respectful. The Commissioners will moderate messages and remove
                            any which may be deemed disrespectful.
                        </li>
 
                        <li>
                            Prizes are not labelled in any particular order; poolies can
                            select any available prize. Prize distribution is done on an
                            elimination basis with the top scoring poolie having first choice,
                            followed by next poolie, etc.
                        </li>
 
                        <li>Play to win and Have fun!</li>
                    </ol>
                </section>
            </div>
        </>
    )
}
 
export default Rules