import React, {useState, useEffect} from 'react';
import './Prizes.css';
import PrizeItem from '../prize-item/PrizeItem';
import PrizeModal from '../prize-modal/PrizeModal';

const Prizes = () => {

    const [prizes, setPrizeData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [ , setError] = useState([]);

    // initial state.
    const [modal, setModal] = useState({
        id: -999,
        name: "",
        image: "",
        input: null,
        correctCode: false,
        isCheckingCode: false,
        hasError: false,
        isCollectingPrize: false
    });

    useEffect(() => {
        fetchPrizes();
    }, []);

    const fetchPrizes = async () => {
        try {
            await fetch(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + `/fetchprizes`)
                .then(res => res.json())
                .then(
                    (data) => {
                        setPrizeData(data);
                        console.log(data, 'Prize');
                    },
                    (error) => {
                        setError(error);
                    }
                )
        } catch (error) {
            throw error;
        }
    };

    const openPrizeModal = (prizeID, prizeName, prizeImage) => {

        // sets the prize details and resets properties that changes the way the prize modal is shown
        setModal(prev => ({
            ...prev,
            id: prizeID,
            name: prizeName,
            image: prizeImage,
            correctCode: false,
            isCheckingCode: false,
            hasError: false,
            isCollectingPrize: false
        }));

        // Clears the form field when opening a prize modal. 
        modal.input.current.value = "";

        setShowModal(!showModal);
    };

    return (
        <div className='content-section bg-light'>

            <div className="container-fluid full-height">

                <div className="row">
                    <div className="col-lg-12 text-center justify-content-center prize-msg">

                        <h1 className="">Prize List</h1>
                        <p className="">The prize list is not yet complete. Please check back frequently as this list expands.</p>
                        <p className="">For now here are the prizes available.</p>

                    </div>
                </div>

                {/* Just a row to contain the boxes of prizes */}
                <div className="row justify-content-center bottom-spacing">
                    {prizes.map((p) => {
                        return (
                            <PrizeItem openModal={openPrizeModal} key={p.ID} prizeID={p.ID} prizeName={p.Name} prizeImage={p.Image} />
                        )
                    })}
                </div>

            </div>

            {/* Modal UI */}
            {
                /* <PrizeCodeContext.Provider value={{ setModal }}>  */
                <PrizeModal mo={{ modal, setModal }} prizeID={modal.id} prizeName={modal.name} prizeImage={modal.image} />
                /* </PrizeCodeContext.Provider> */
            }

        </div>
    );
};

export default Prizes;