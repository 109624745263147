import React from "react";
import Modal from 'react-bootstrap/Modal';

const ViewModel = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="prize-modal-container"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" >
                    {props.pName}
                </Modal.Title>
            </Modal.Header>
            <form>
                <Modal.Body>
                    <p>ID: {props.pID}</p>
                    <p>Is Claimed: {props.pClaimed}</p>
                    <img 
                        className="img-fluid mx-auto d-block"
                        src={props.srcPath + props.pImg}
                        alt={props.pName} 
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={props.onHide} className='btn btn-danger'>Exit</button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default ViewModel;