import React, { useEffect, useState } from 'react';
import './PrizesUI.css';
import ViewModal from './Modals/ViewModal.js';
import AddModal from './Modals/AddModal.js';
import UpdateModal from './Modals/UpdateModal.js';
import DeleteModal from './Modals/DeleteModal.js';

const PrizesUI = () => {
    const [prizes, setPrizeData] = useState([]);
    const [ , setError] = useState([]);
    const [showPrize, setShowPrize] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const [pModal, setPModal] = useState ({
        id: -999,
        name: "",
        image: "",
        claimed: -999,
        srcPath: "",
        input: null
    });
    
    useEffect(() => {
        fetchPrizes();
    }, []);

    const openModal = (pID, pName, pImage, pClaimed, src, status) => {
        // sets the prize details and resets properties that changes the way the prize modal is shown
        setPModal({
            // ...prev,
            id: pID,
            name: pName,
            image: pImage,
            claimed: pClaimed,
            srcPath: src
        });

        // Clears the form field when opening a prize modal. 
        pModal.input = "";
        if (status === 'View') {
            setShowPrize(true);
        } else if (status === 'Update') {
            setShowUpdate(true);
        } else if (status === 'Delete') {
            setShowDelete(true);
        }
    };

    const fetchPrizes = async () => {
        try {
            await fetch(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + `/getallprizes`)
                .then(res => res.json())
                .then(
                    (data) => {
                        setPrizeData(data);
                        console.log(data, 'Prize');
                    },
                    (error) => {
                        setError(error);
                    }
                )
        } catch (error) {
            throw error;
        }
    };

    return (
        <div className='UIcontainer'>
            <div className='prizeUI'>
                 <h1 className='prize-heading'>Prizes</h1>
                 <p className='prizes-desc'>Add / Update / Delete Prizes</p>
                 <button 
                    onClick={() => setShowAdd(true)} 
                    className='btn btn-info upload'
                >
                    Add Prize
                </button>
                <div className='row justify-content-center'>
                    {prizes.map((p) => {
                        return (
                            <div className='prizeCard'>
                                <div 
                                    className='column UIcard' 
                                    tabIndex='0' 
                                    data-toggle='modal' 
                                    data-target='#myModal'
                                    onClick={() => openModal(p.ID, p.Name, p.Image, p.IsClaimed.data, process.env.REACT_APP_HOCKEYPOOLV2_IMG_URL, 'View')}
                                >
                                    <div className='containerHeading'>
                                        <div className='card-prizes card-corner'>
                                            <div className='card-header-prizes'>
                                                <p className='text-prizes'>{p.Name}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <img
                                        alt={p.Name}
                                        className='img-fluid mx-auto d-block'
                                        src={process.env.REACT_APP_HOCKEYPOOLV2_IMG_URL + p.Image}
                                    />
                                </div>
                                <div className='actions'>
                                    <p>Is Claimed: {p.IsClaimed.data}</p>
                                    <button 
                                        onClick={() => openModal(p.ID, p.Name, p.Image, p.IsClaimed.data, process.env.REACT_APP_HOCKEYPOOLV2_IMG_URL, 'Update')} 
                                        className='btn btn-info update'
                                        variant="primary" 
                                    >Update</button>
                                    <button
                                        onClick={() => openModal(p.ID, p.Name, p.Image, p.IsClaimed.data, process.env.REACT_APP_HOCKEYPOOLV2_IMG_URL, 'Delete')} 
                                        className='btn btn-danger delete'
                                        variant="primary"
                                    >Delete</button>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <ViewModal 
                    show={showPrize}
                    onHide={() => setShowPrize(false)}
                    modal={{pModal, setPModal}} 
                    pID={pModal.id}
                    pName={pModal.name}
                    pImg={pModal.image}
                    pClaimed={pModal.claimed}
                    srcPath={pModal.srcPath}
                />
                <AddModal show={showAdd} onHide={() => setShowAdd(false)}/>
                <UpdateModal 
                    show={showUpdate} 
                    onHide={() => setShowUpdate(false)} 
                    modal={{pModal, setPModal}} 
                    pID={pModal.id}
                    pName={pModal.name}
                    pImg={pModal.image}
                    pClaimed={pModal.claimed}
                    srcPath={pModal.srcPath}    
                />
                <DeleteModal 
                    show={showDelete}
                    onHide={() => setShowDelete(false)}
                    modal={{pModal, setPModal}} 
                    pID={pModal.id}
                    pName={pModal.name}
                    pImg={pModal.image}
                    pClaimed={pModal.claimed}
                    srcPath={pModal.srcPath}
                />
            </div>
        </div>
    );
}

export default PrizesUI;
