import React, { useEffect, useState } from "react";
import { RegistrationContext } from "../context/RegistrationContext";

export const RegistrationProvider = ({children}) => {
    const [registrationPool, setRegistrationPool] = useState([]);
    const [ , setError] = useState([]);

    useEffect(() => {
        fetchPool();
    }, []);

    const fetchPool = async () => {
        try {
            await fetch(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + `/fetchpool`)
                .then(res => res.json())
                .then(
                    (data) => {
                        setRegistrationPool(data);
                        console.log(data, 'Pool');
                    },
                    (error) => {
                        setError(error);
                    }
                )
        } catch (error) {
            throw error;
        }
    };

    return (
        <RegistrationContext.Provider value={ registrationPool } >
            {children}
        </RegistrationContext.Provider>
    );
};