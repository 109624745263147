import React from 'react';
import Scoring from '../scoring/scoring';
import Rules from '../rules/Rules';

function RulesAndScoring(){
  return(
    <div className='content-section bg-light'>
      <Rules/>
      <Scoring />
    </div>
  );
}

export default RulesAndScoring