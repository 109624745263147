import React from "react";

const Standings = () => {
    return (
        <div className="container-fluid full-height">
            <h1>Standings</h1>
            <div dangerouslySetInnerHTML={iframe()}>
            </div>
        </div>
    );
};

function iframe() {
   const iframe='<iframe marginheight="0" marginwidth="0" frameBorder="0" src="https://www.officepools.com/nhl/classic/widget/A4UA2FK" width="500" height="500"></iframe>';

   return {
        __html: iframe
    };
    
};

export default Standings;