import React, { useState, useContext } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import Layout from '../layout/Layout';
import Homepage from '../homepage/Homepage';
import About from '../about/About'
import Details from '../Details/Details.js'
import RulesAndScoring from '../rulesAndScoring/RulesAndScoring';
// import Rules from '../rules/Rules';
// import Scoring from '../scoring/scoring';
import Qna from '../qna/Qna';
import Sponsors from '../sponsors/Sponsors';
import Prizes from '../prizes/Prizes';
import Login from '../login/Login';
import Admin from '../admin/Admin';
import Standings from '../standings/Standings';
import PrivateRoute from '../../routes/PrivateRoute';
import { RegistrationContext } from '../../context/RegistrationContext';
import NotFound from '../not-found-page/PageNotFound';
import Congratulations from '../congrats/Congrats';
import SponsorUpload from "../sponsors-upload/SponsorUpload";

const HasLayout = () => {
    const [year] = useState(new Date().getFullYear());
    // const [registrationPool, setRegistrationPool] = useState([]);
    //const [error, setError] = useState([]);
    const registrationPool = useContext(RegistrationContext);

    return (
        <Router>
            <Layout year={year}>
                <Routes>
                    <Route exact path="/" element={<Homepage />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/details" element={registrationPool.map(pool => {
                        return (
                            <Details year={year} registrationIsClosed={pool.isClosed} registrationLink={pool.registrationLink} />
                        )
                    })} />
                    <Route path="/rulesAndScoring" element={<RulesAndScoring />} />
                    {/* <Route path="/scoring" element={<Scoring />} /> */}
                    <Route path="/faq" element={registrationPool.map(pool => {
                        return (
                            <Qna year={year} registrationIsClosed={pool.isClosed} registrationLink={pool.registrationLink} />
                        )
                    })} />
                    <Route path="/sponsors" element={<Sponsors />} />
                    <Route path="/prizes" element={<Prizes />} />
                    <Route path="/standings" element={<Standings />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/admin" element={registrationPool.map(pool => {
                        return (
                            <PrivateRoute>
                                <Admin registrationIsClosed={pool.isClosed} registrationLink={pool.registrationLink} />
                            </PrivateRoute>
                        )
                    })} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/congratulations" element={<Congratulations year={year} />} />
                    <Route path="/managesponsors" element={<SponsorUpload/>} />
                    {/* <Route path="/allsponsors" element={<AllSponsors/>}/> */}
                </Routes>
            </Layout>
        </Router>
    );
};

export default HasLayout;
