import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
 
const UpdateSponsorsModal = (props) => {
    const [sName, setSponsorName] = useState([]);
    const [sPlat, setPlat] = useState([]);
    const [imgFile, setImage] = useState([]);
 
    const updateSponsor = async () => {
        // creating a form to hold the name and image
        const formData = new FormData();
        formData.append('sponsorId', props.sID);
        formData.append('sponsorName', sName);
        formData.append('sponsorPlat', sPlat);
        formData.append('sponsorImg', props.sImg);
        formData.append('image', imgFile);
 
        await axios.post(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + `/updatesponsor`, formData, { headers: { 'content-type': 'multipart/form-data' } })
            .catch(err => {
                console.error(err);
            })
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="sponsor-modal-container"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" >
                    Updating Sponsor "{props.sName}"
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={updateSponsor}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-6 sponsor-img" style={{ padding: '10px', borderRight: '1px solid rgb(201, 196, 196)' }}>
                            <img
                                className="img-fluid mx-auto d-block"
                                src={props.srcPath + props.sImg}
                                alt={props.sName}
                            />
                        </div>
                        <div className="col-sm-6 sponsor-name">
                            <p>ID: {props.sID}</p>
                            {/* <p>Is Claimed: {props.pClaimed}</p> */}
                            <label htmlFor="sponsorName">Sponsor Name:</label>
                            <input
                                name="sponsorName"
                                type="text"
                                className="form-control"
                                placeholder={props.sName}
                                onChange={(e) => setSponsorName(e.target.value)}
                            />
 
                            <label htmlFor="sponsorPlat">Sponsor Rank:</label>
                            <select name="sponsorPlat"
                                type="text"
                                className="form-control"
                                defaultValue= ""
                                placeholder={parseInt(sPlat)}
                                onChange={(e) => setPlat(parseInt(e.target.value))}>
                                <option value="">- Select - (Or leave for current rank)</option>
                                <option value="1">Platinum</option>
                                <option value="2">Gold</option>
                                <option value="3">Silver</option>
                                <option value="4">Bronze</option>
                                <option value="0">Other</option>
                            </select>
 
 
                            <label htmlFor='sponsorImage'>Sponsor Image:</label>
                            <input
                                name='sponsorImage'
                                type='file'
                                className='form-control'
                                onChange={(e) => setImage(e.target.files[0])}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button class='btn btn-success'>Update</button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
 
export default UpdateSponsorsModal;