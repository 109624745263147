import React from 'react';
import Landing from '../landing/Landing';
import HomepageContent from '../homepage-content/homepageContent';
import Sponsors from '../sponsors/Sponsors';

const Homepage = () => {
    return (
        <>
            <Landing />
            <HomepageContent />            
            <Sponsors/>
        </>
    );
}

export default Homepage;