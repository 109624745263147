import React from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";
import "./Navbar.css";
import firebaseAuth from "../../assets/config/FirebaseAuth";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
//import { Dropdown } from "react-bootstrap";
// import { AuthContext } from "../../context/AuthContext";
// import { RegistrationContext } from "../../context/RegistrationContext";

const Navbar = (props) => {
  // const { registrationPool } = useContext(RegistrationContext);
  // const currentUser = useContext(AuthContext);
  const ABOUT = "/about#top";
  const DETAILS = "/details#top";
  const FAQ = "/faq#top";
  //const SPONSORS = "/sponsors#top";
  const PRIZES = "/prizes#top";
  const RULESANDSCORING = "/rulesAndScoring#top";
  //const RULES = "/rules#top";
  //const SCORING = "/scoring#top";
  const STANDINGS = "/standings#top";
  const ADMIN = "/admin#top";
  const SPONSORUPLOAD = "/managesponsors#top";
  //const ALLSPONSORS = "/allsponsors#top";

  const handleLogout = () => {
    sessionStorage.removeItem("LoggedInUser");
    firebaseAuth.auth().signOut().then(function() {
      // Sign-out successful.
      console.log('logged out');
    }).catch(function(error) {
      console.error(error);
    });
    window.location.reload(false);
  }

  return(
    // Used as a wrapper for react's div wrapper when rendering the jsx, react.fragment will not show up on the dom as a div.
    <>
      {/* collapseOnSelect is a Navbar attribute/prop to collapse nav list if a link is clicked on. */}
      <nav className="red-background-navbar navbar navbar-expand-lg fixed-top navbar-light navbar-custom">
        {/* Container to add margin adjustment on the left and right, w/o it then it take the entire horizontal width of the navbar */}
        <div className="container">
          <a className="navbar-brand" href="/">
            ITS FedHealth Hockey Playoff Pool
          </a>
          {/* Navbar.Toggle is needed for responsiveness, it is the button when screen width in smaller breakpoints. */}
          {/*  <Navbar.Toggle /> */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsiveTarget"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* Navbar.Collapse is the behavior once the buttons is pressed it will expand and collapse when clicked again. */}
          <div className="collapse navbar-collapse" id="navbarResponsiveTarget">
            {/* ml-auto means margin left auto margin adjustment */}
            {/* ml-auto is a bootstrap class */}
            <ul className="navbar-nav ms-auto">
              {/* has active class to make it black */}
              {/* add SMOOTH prop to create smooth scrolling */}
              {/* https://reactrouter.com/web/api/NavLink for NavLink reference */}
              {/* (MATCH, LOCATION) needed params otherwise function doesnt register for isActive prop. */}
              <li className="nav-item">
                <NavLink
                  smooth
                  exact
                  to={ABOUT}
                  activeClassName="selected"
                  isActive={(match, location) => {
                    let routeName = `${location.pathname}${location.hash}`;
                    return routeName === ABOUT ? true : false;
                  }}
                  className="nav-link"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  About Us
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  smooth
                  exact
                  to={DETAILS}
                  activeClassName="selected"
                  isActive={(match, location) => {
                    let routeName = `${location.pathname}${location.hash}`;
                    return routeName === DETAILS ? true : false;
                  }}
                  className="nav-link"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Details
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  exact
                  to={RULESANDSCORING}
                  className="nav-link"
                  activeClassName="selected"
                  isActive={(match, location) => {
                    let routeName = `${location.pathname}${location.hash}`;
                    return routeName === RULESANDSCORING ? true : false;
                  }}
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Rules
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  smooth
                  exact
                  to={STANDINGS}
                  activeClassName="selected"
                  isActive={(match, location) => {
                    let routeName = `${location.pathname}${location.hash}`;
                    return routeName === STANDINGS ? true : false;
                  }}
                  className="nav-link"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Standings
                </NavLink>
              </li>

              {/* <li className="nav-item">
                <NavLink
                  smooth
                  exact
                  to={RULES}
                  activeClassName="selected"
                  isActive={(match, location) => {
                    let routeName = `${location.pathname}${location.hash}`;
                    return routeName === RULES ? true : false;
                  }}
                  className="nav-link"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Rules
                </NavLink>
              </li> */}

              <li className="nav-item">
                <NavLink
                  smooth
                  exact
                  to={PRIZES}
                  activeClassName="selected"
                  isActive={(match, location) => {
                    let routeName = `${location.pathname}${location.hash}`;
                    return routeName === PRIZES ? true : false;
                  }}
                  className="nav-link"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Prizes
                </NavLink>
              </li>

              {/* <li className="nav-item">
                <NavLink
                  smooth
                  exact
                  to={SPONSORS}
                  activeClassName="selected"
                  isActive={(match, location) => {
                    let routeName = `${location.pathname}${location.hash}`;
                    return routeName === SPONSORS ? true : false;
                  }}
                  className="nav-link"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Sponsors
                </NavLink>
              </li> */}

              <li className="nav-item">
                <NavLink
                  smooth
                  exact
                  to={FAQ}
                  activeClassName="selected"
                  isActive={(match, location) => {
                    let routeName = `${location.pathname}${location.hash}`;
                    return routeName === FAQ ? true : false;
                  }}
                  className="nav-link"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  FAQ
                </NavLink>
              </li>

              <li
                className={
                  props.registrationIsClosed
                    ? "nav-item visible"
                    : "nav-item invisible"
                }
              >
                <a
                  className="nav-link"
                  target="blank"
                  href={props.registrationLink}
                >
                 {/* Requires a span to hold the data-toggle, data-target otherwise if put on <a> it will break it */}
                  <span
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    Register
                  </span>
                </a>
              </li>

              {/* will only display "Admin Dashboard" and "Logout" if admin is logged in */}
               <li className={
                !!sessionStorage.getItem("LoggedInUser") === true
                  ? "nav-item visible"
                  : "nav-item invisible"
              }>
               <li class="nav-item dropdown">
                <button type="button" class="nav-link dropdown-toggle" href="#" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Admin
                </button>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <DropdownItem class="dropdown-item">
                    <NavLink
                      smooth
                      exact
                      to={ADMIN}
                      activeClassName="selected"
                      isActive={(match, location) => {
                        let routeName = `${location.pathname}${location.hash}`;
                        return routeName === ADMIN ? true : false;
                      }}
                      className="nav-link"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      Admin Dashboard
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem class="dropdown-item">
                    <NavLink
                        smooth
                        exact
                        to={SPONSORUPLOAD}
                        activeClassName="selected"
                        isActive={(match, location) => {
                          let routeName = `${location.pathname}${location.hash}`;
                          return routeName === SPONSORUPLOAD ? true : false;
                        }}
                        className="nav-link"
                        data-toggle="collapse"
                        data-target=".navbar-collapse.show"
                      >
                        Manage Sponsors
                      </NavLink>
                  </DropdownItem>
                  {/* <a class="dropdown-item">
                    <NavLink
                        smooth
                        exact
                        to={ALLSPONSORS}
                        activeClassName="selected"
                        isActive={(match, location) => {
                          let routeName = `${location.pathname}${location.hash}`;
                          return routeName === ALLSPONSORS ? true : false;
                        }}
                        className="nav-link"
                        data-toggle="collapse"
                        data-target=".navbar-collapse.show"
                      >
                        All Sponsors
                      </NavLink>
                  </a> */}
              </div>
            </li>
            </li> 

              <li className={
                !!sessionStorage.getItem("LoggedInUser") === true
                  ? "nav-item visible"
                  : "nav-item invisible"
                }
                onClick={handleLogout}
              >
                <span 
                  activeClassName="selected"
                  className="nav-link loggingOut"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  Logout
                </span>
              </li>
              
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
