import React from 'react';
import './PrizeItem.css';

const PrizeItem = (props) => {
    let prizeImg = process.env.REACT_APP_HOCKEYPOOLV2_IMG_URL + props.prizeImage;
    
    return (
        <React.Fragment>
            <div onClick={() => { props.openModal(props.prizeID, props.prizeName, props.prizeImage); }} className="column" tabIndex='0' data-toggle='modal' data-target='#myModal'>

                <div className='containerHeading'>
                    <div className='card-prizes card-corner'>
                        <div className='card-header-prizes'>
                            <p className='text-prizes'>{props.prizeName}</p>
                        </div>
                    </div>
                </div>
                <div className='imgContainer'>
                    <img 
                        alt={props.prizeName} 
                        className='img-fluid mx-auto d-block' 
                        src={prizeImg} 
                    />
                </div>
            </div>

        </React.Fragment>
    );
};

export default PrizeItem;