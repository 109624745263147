import React from 'react';
import './PageNotFound.css';
import { Link } from 'react-router-dom';
import newPuck from "../../assets/images/loadpuck.gif"
import ice from "../../assets/images/Ice-bg.jpg"

function NotFound() {
    const backgroundStyle = {
        backgroundImage: `url(${ice})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'repeat', // Add this line
    };

    return (
        <div className="not-found-page" style={backgroundStyle}>
            <div className="content">
                <h1>Oops!</h1>
                <h2 >404 - Page Not Found</h2>
                <p style={{ color: "black", fontWeight: 'bold' }}>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
                <Link to="/" className="home-button">Go to Homepage</Link>
            </div>
            <div className="hockey-puck">
                <img src={newPuck} alt="Puck Logo" className="puck-logo" width={"65%"}/>
            </div>
        </div>
    );
    
}

export default NotFound;
