import React from "react";
import "./Landing.css";
import Slideshow from "../slideshow/Slideshow";

const Landing = () => {
  return (
    <React.Fragment>
        <div id="home" className="bg bg-light">
          <Slideshow />
        </div>
      {/* </header> */}
    </React.Fragment>
  );
};

export default Landing;
