import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

const AddModel = (props) => {
    const [pName, setPrizeName] = useState([]);
    const [imgFile, setImage] = useState([]);

    const addingPrize = async () => {
        // creating a form to hold the name and image
        const formData = new FormData();
        formData.append('prizeName', pName);
        formData.append('image', imgFile);
    
        await axios.post(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + `/uploadprize`, formData, {headers: { 'content-type': 'multipart/form-data' }})
            .catch(err => {
                console.error(err);
        })
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="prize-modal-container"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" >
                    Add New Prize
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={addingPrize}>
                <Modal.Body>
                    <label htmlFor='prizeName'>Prize Name:</label>
                    <input 
                        name='prizeName'
                        type='text'
                        className='form-control'
                        placeholder='Enter Prize Name'
                        onChange={(e) => setPrizeName(e.target.value)}
                    />
                    <label htmlFor='prizeImage'>Prize Image:</label>
                    <input
                        name='prizeImage'
                        type='file'
                        className='form-control'
                        onChange={(e) => setImage(e.target.files[0])}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button class='btn btn-success'>Add New Prize</button>
                    {/* <button onClick={props.onHide} className='btn btn-danger'>Cancel</button> */}
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default AddModel;