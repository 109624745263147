import React from "react";
import "./Details.css";
import { Link } from 'react-router-dom';

const Details = (props) => {
  //console.log(props.registrationLink);
  return (
    <>
      <section className="content-section text-center font-weight-bold" id="details">
        <div className="container">
          <div className="content-section-heading">
            <h2>
              Welcome to the {props.year} Federated Health Hockey Playoff Pool!
            </h2>
            <div class="details">
              <p id="intro">
                Now entering its 12th year, this Playoff Pool has been a great success with your support!
                Last year we raised almost $2800 to help the Federated Health Charities! Thank You!!
              </p>

              <h2 id="whyThisPool" className="text-center text-danger pb-2">
                Last year was our greatest year to date…Help us make 2024 our biggest year yet!
              </h2>

              <p>
                Are you wondering…why this pool? Or maybe you’re thinking why not a different cash prize pool?
              </p>

              <p>
                So yes, this is definitely not your typical pool where you are using your hockey smarts to get rich, 
                but you definitely get a chance at some awesome prizes! Last year the top 45 participants were able to claim a prize… 
                and like previous years we've got tons of prizes up for grabs!
              </p>

              <p>
                What prizes do we have?
              </p>

              <p>
                Well, we're glad you asked! Take a look at our prize list:
              </p>

              <Link to="/prizes" className="btn btnWidth btn-outline-primary">
                View Prizes
              </Link>

              <br /><br />
              <h1 id="registrationPrize" className="text-danger">
                All this for only a Registration Fee of $25/team
                <span id="puck-tastic">puck-tastic!</span>
              </h1>
              <h4>Remember, you can't win if you're not in!</h4><br />
              <span className={props.registrationIsClosed ? "" : "disabled"}>
                <a
                  href={props.registrationIsClosed ? props.registrationLink : ""}
                  className={
                    props.registrationIsClosed
                      ? "btn btnWidth btn-outline-danger"
                      : "btn btnWidth btn-outline-danger disabled"
                  }
                >
                  Register
                </a>
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Details;