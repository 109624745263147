import { React, useState, useEffect } from "react";
import { Container, Form, Button, Col, Row } from "react-bootstrap";
import "./SponsorUpload.css";
import UpdateSponsorsModal from "./Modals/UpdateSponsorsModal.js";

export const SponsorUpload = () => {
  const [platinumSponsors, SetPlatinumSponsors] = useState([]);
  const [goldSponsors, SetGoldSponsors] = useState([]);
  const [silverSponsors, SetSilverSponsors] = useState([]);
  const [bronzeSponsors, SetBronzeSponsors] = useState([]);
  const [otherSponsors, SetOtherSponsors] = useState([]);
  //my stuff v
  const [showUpdate, setShowUpdate] = useState(false);
  const [sModal, setSModal] = useState({
    id: -999,
    name: "",
    IsPlatinum: -999,
    image: "",
    srcPath: "",
    input: null,
  });

  useEffect(() => {
    fetchPlatinumSponsors();
    fetchGoldSponsors();
    fetchSilverSponsors();
    fetchBronzeSponsors();
    fetchOtherSponsors();
  }, []);

  //mine v
  const openModal = (sID, sName, sPlat, sImage, src, status) => {
    // sets the sponsor details and resets properties that changes the way the modal is shown
    setSModal({
      id: sID,
      name: sName,
      IsPlatinum: sPlat,
      image: sImage,
      srcPath: src,
    });

    // Clears the form field when opening a modal.
    sModal.input = "";
    if (status === "Update") {
      setShowUpdate(true);
    }
  };
  //mine ^

  const fetchPlatinumSponsors = async () => {
    try {
      //change link accordingly
      await fetch(
        process.env.REACT_APP_HOCKEYPOOLV2_API_URL + "/platinumsponsors"
      )
        .then((res) => res.json())
        .then((data) => {
          SetPlatinumSponsors(data);
        });
    } catch (error) {
      throw error;
    }
  };

  const fetchGoldSponsors = async () => {
    try {
      //change link accordingly
      await fetch(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + "/goldsponsors")
        .then((res) => res.json())
        .then((data) => {
          SetGoldSponsors(data);
        });
    } catch (error) {
      throw error;
    }
  };

  const fetchSilverSponsors = async () => {
    try {
      //change link accordingly
      await fetch(
        process.env.REACT_APP_HOCKEYPOOLV2_API_URL + "/silversponsors"
      )
        .then((res) => res.json())
        .then((data) => {
          SetSilverSponsors(data);
        });
    } catch (error) {
      throw error;
    }
  };

  const fetchBronzeSponsors = async () => {
    try {
      //change link accordingly
      await fetch(
        process.env.REACT_APP_HOCKEYPOOLV2_API_URL + "/bronzesponsors"
      )
        .then((res) => res.json())
        .then((data) => {
          SetBronzeSponsors(data);
        });
    } catch (error) {
      throw error;
    }
  };

  const fetchOtherSponsors = async () => {
    try {
      //change link accordingly
      await fetch(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + "/othersponsors")
        .then((res) => res.json())
        .then((data) => {
          SetOtherSponsors(data);
        });
    } catch (error) {
      throw error;
    }
  };

  function SponsorAdded(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    if (!formData.get("name") || !formData.get("image")) {
      alert("You cannot leave fields empty!");
    } else {
      let result = window.confirm("Are you sure you want to add this sponsor?");
      if (result) {
        fetch(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + "/uploadsponsor", {
          method: "POST",
          body: formData,
        })
          .then(() => {
            window.location.reload(); // Reload the page after successful submission
          })
          .catch((error) => console.error("Error uploading sponsor:", error));
      }
    }
  }

  function deleteConfirm(id) {
    let result = window.confirm(
      "Are you sure you want to delete this sponsor?"
    );
    if (result) {
      try {
        console.log("Sponsor has been deleted!!!!!!");
        fetch(
          process.env.REACT_APP_HOCKEYPOOLV2_API_URL + "/sponsor/delete/" + id
        );
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("sponsor has not been deleted");
    }
  }

  function hideSponsor(id) {
    let result = window.confirm("Are you sure you want to hide this Sponsor?");

    if (result) {
      fetch(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + "/hidesponsor/" + id);
      window.location.reload();
    } else {
      console.log("Did not hide sponsor");
    }
  }

  function showSponsor(id) {
    let result = window.confirm("Are you sure you want to show this Sponsor?");

    if (result) {
      fetch(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + "/showsponsor/" + id);
      window.location.reload();
    } else {
      console.log("Did not show sponsor");
    }
  }

  return (
    <div>
      {/* Sponsor upload form */}
      <Container className="main-content">
        <Row className="justify-content-center">
          <Col className="col-12 col-sm-9 col-lg-5">
            {/* change link accordingly */}
            {/* http://45.82.73.61/api/uploadsponsor */}
            <div className="form-content">
              <h2>
                <u>Add a Sponsor</u>
              </h2>
              <Form name="sponsorForm" onSubmit={SponsorAdded}>
                <Form.Group className="mb-3">
                  <Form.Label className="form-labels">
                    <b>Sponsor Name:</b>
                  </Form.Label>
                  <Form.Control type="text" name="name" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="form-labels">
                    <b>Platinum Sponsor:</b>
                  </Form.Label>
                  <Form.Select name="isPlatinum">
                    <option value="0">Other</option>
                    <option value="1">Platinum</option>
                    <option value="2">Gold</option>
                    <option value="3">Silver</option>
                    <option value="4">Bronze</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="form-labels">
                    <b>Select Sponsor Image:</b>
                  </Form.Label>
                  <Form.Control type="file" name="image" />
                </Form.Group>
                <Button className="form-button" type="submit">
                  Add
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Below is the listing of sponsors */}
      <div className="list-sponsors">
        <Container className="text-center">
          <h3>Platinum Sponsors</h3>
          <Row xs={3} md={4} lg={5}>
            {platinumSponsors.map((sponsor) => (
              <Col className="mb-4">
                <div class="card">
                  <img
                    src={
                      process.env.REACT_APP_HOCKEYPOOLV2_IMG_URL +
                      `${sponsor.Image}`
                    }
                    alt={sponsor.Name}
                    className="img-thumbnail"
                    width={200}
                    style={{ marginBottom: "15px" }}
                  />
                  <div class="card-body">
                    <p>
                      <u>{sponsor.Name}</u>
                    </p>
                  </div>

                  <div>
                    {sponsor.toShow ? (
                      <button
                        type="button"
                        className="btn"
                        id="toHide"
                        onClick={() => hideSponsor(sponsor.ID)}
                      >
                        Hide
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn"
                        id="toShow"
                        onClick={() => showSponsor(sponsor.ID)}
                      >
                        Show
                      </button>
                    )}
                    <br />
                    <button
                      type="button"
                      onClick={() =>
                        openModal(
                          sponsor.ID,
                          sponsor.Name,
                          sponsor.isPlatinum,
                          sponsor.Image,
                          process.env.REACT_APP_HOCKEYPOOLV2_IMG_URL,
                          "Update"
                        )
                      }
                      className="btn"
                      id="updateButton"
                    >
                      Update
                    </button>
                    <br />
                    <button
                      type="button"
                      className="btn"
                      id="deleteButton"
                      onClick={() => deleteConfirm(sponsor.ID)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>

          <h3> Gold Sponsors </h3>
          <Row xs={3} md={4} lg={5}>
            {goldSponsors.map((sponsor) => (
              <Col className="mb-4">
                <div class="card">
                  <img
                    src={
                      process.env.REACT_APP_HOCKEYPOOLV2_IMG_URL +
                      `${sponsor.Image}`
                    }
                    alt={sponsor.Name}
                    className="img-thumbnail"
                    width={200}
                    style={{ marginBottom: "15px" }}
                  />
                  <div class="card-body">
                    <p>
                      <u>{sponsor.Name}</u>
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={() =>
                      openModal(
                        sponsor.ID,
                        sponsor.Name,
                        sponsor.isPlatinum,
                        sponsor.Image,
                        process.env.REACT_APP_HOCKEYPOOLV2_IMG_URL,
                        "Update"
                      )
                    }
                    className="btn"
                    id="updateButton"
                  >
                    Update
                  </button>

                  <div>
                    {sponsor.toShow ? (
                      <button
                        type="button"
                        className="btn"
                        id="toHide"
                        onClick={() => hideSponsor(sponsor.ID)}
                      >
                        Hide
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn"
                        id="toShow"
                        onClick={() => showSponsor(sponsor.ID)}
                      >
                        Show
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn"
                      id="deleteButton"
                      onClick={() => deleteConfirm(sponsor.ID)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <h3> Silver Sponsors </h3>
          <Row xs={3} md={4} lg={5}>
            {silverSponsors.map((sponsor) => (
              <Col className="mb-4">
                <div class="card">
                  <img
                    src={
                      process.env.REACT_APP_HOCKEYPOOLV2_IMG_URL +
                      `${sponsor.Image}`
                    }
                    alt={sponsor.Name}
                    className="img-thumbnail"
                    width={200}
                    style={{ marginBottom: "15px" }}
                  />
                  <div class="card-body">
                    <p>
                      <u>{sponsor.Name}</u>
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={() =>
                      openModal(
                        sponsor.ID,
                        sponsor.Name,
                        sponsor.isPlatinum,
                        sponsor.Image,
                        process.env.REACT_APP_HOCKEYPOOLV2_IMG_URL,
                        "Update"
                      )
                    }
                    className="btn"
                    id="updateButton"
                  >
                    Update
                  </button>
                  <div>
                    {sponsor.toShow ? (
                      <button
                        type="button"
                        className="btn"
                        id="toHide"
                        onClick={() => hideSponsor(sponsor.ID)}
                      >
                        Hide
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn"
                        id="toShow"
                        onClick={() => showSponsor(sponsor.ID)}
                      >
                        Show
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn"
                      id="deleteButton"
                      onClick={() => deleteConfirm(sponsor.ID)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <h3> Bronze Sponsors </h3>
          <Row xs={3} md={4} lg={5}>
            {bronzeSponsors.map((sponsor) => (
              <Col className="mb-4">
                <div class="card">
                  <img
                    src={
                      process.env.REACT_APP_HOCKEYPOOLV2_IMG_URL +
                      `${sponsor.Image}`
                    }
                    alt={sponsor.Name}
                    className="img-thumbnail"
                    width={200}
                    style={{ marginBottom: "15px" }}
                  />
                  <div class="card-body">
                    <p>
                      <u>{sponsor.Name}</u>
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={() =>
                      openModal(
                        sponsor.ID,
                        sponsor.Name,
                        sponsor.isPlatinum,
                        sponsor.Image,
                        process.env.REACT_APP_HOCKEYPOOLV2_IMG_URL,
                        "Update"
                      )
                    }
                    className="btn"
                    id="updateButton"
                  >
                    Update
                  </button>
                  <div>
                    {sponsor.toShow ? (
                      <button
                        type="button"
                        className="btn"
                        id="toHide"
                        onClick={() => hideSponsor(sponsor.ID)}
                      >
                        Hide
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn"
                        id="toShow"
                        onClick={() => showSponsor(sponsor.ID)}
                      >
                        Show
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn"
                      id="deleteButton"
                      onClick={() => deleteConfirm(sponsor.ID)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <h3 id="second-title">Other Sponsors</h3>
          <Row xs={3} md={4} lg={5}>
            {otherSponsors.map((sponsor) => (
              <Col className="mb-4">
                <div class="card">
                  <img
                    src={
                      process.env.REACT_APP_HOCKEYPOOLV2_IMG_URL +
                      `${sponsor.Image}`
                    }
                    alt={sponsor.Name}
                    className="img-thumbnail"
                    width={200}
                    style={{ marginBottom: "15px" }}
                  />
                  <div class="card-body">
                    <p>
                      <u>{sponsor.Name}</u>
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={() =>
                      openModal(
                        sponsor.ID,
                        sponsor.Name,
                        sponsor.isPlatinum,
                        sponsor.Image,
                        process.env.REACT_APP_HOCKEYPOOLV2_IMG_URL,
                        "Update"
                      )
                    }
                    className="btn"
                    id="updateButton"
                  >
                    Update
                  </button>
                  <div>
                    {sponsor.toShow ? (
                      <button
                        type="button"
                        className="btn"
                        id="toHide"
                        onClick={() => hideSponsor(sponsor.ID)}
                      >
                        Hide
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn"
                        id="toShow"
                        onClick={() => showSponsor(sponsor.ID)}
                      >
                        Show
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn"
                      id="deleteButton"
                      onClick={() => deleteConfirm(sponsor.ID)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <UpdateSponsorsModal
        show={showUpdate}
        onHide={() => setShowUpdate(false)}
        modal={{ sModal, setSModal }}
        sID={sModal.id}
        sName={sModal.name}
        sImg={sModal.image}
        sPlat={sModal.IsPlatinum}
        srcPath={sModal.srcPath}
      />
    </div>
  );
};

export default SponsorUpload;
