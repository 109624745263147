import React, {useState, useEffect} from 'react';
import './scoring.css';
import Forward from '../../assets/images/ScoringIcons/hockey.png';
//import Rookie from '../../assets/images/ScoringIcons/player.png';
import Defence from '../../assets/images/ScoringIcons/hockey-sticks.png';
import Goalie from '../../assets/images/ScoringIcons/goalie.png';

function Scoring() {

    const [scoreData, setScoreData] = useState([{}]);
    const [ , setError] = useState([]);

    useEffect(() => {
        fetchScoreData(); 
    }, []);

    const fetchScoreData = async () => {
        try {
            await fetch(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + `/fetchscoring`)
                .then(res => res.json())
                .then(
                    (data) => {
                        setScoreData(data);
                        console.log(data, 'Scoring');
                    },
                    (error) => {
                        setError(error);
                    }
                )
        } catch (error) {
            throw error;
        }

    };
    
    return(
        <div>
            <div class="container scoringContent">
            <h1>Scoring</h1>
                <div class="row">
                    <div class="col-lg-4 col-md-6 cardDiv">
                        <div class="card">
                        <div class="card-header">
                            <img class="icons" src={Forward} alt="Forward icon"></img>
                            <div className='iconText'>
                                Forward
                            </div>
                        </div>
                        <div class="card-body">
                        <table class="table">
                            <thead style={{borderBottom:'solid 2px #b80000'}}>
                                <tr>
                                <th scope="col">Attribute</th>
                                <th scope="col">Points</th>
                                </tr>
                            </thead>
                            <tbody>
                                {scoreData.map((score, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{score.FORWARDS}</td>
                                            <td style={(score.POINTS > 0)? { backgroundColor:'rgba(158, 222, 175, 0.8)'}: (score.POINTS < 0)?{ backgroundColor:'rgba(219, 158, 156, 0.8)'}: {}}>{score.POINTS}</td>
                                        </tr>
                                    )
                                })

                                }
                            </tbody>
                        </table>
                        </div>
                        </div>
                    </div>
                    {/* <div class="col-lg-3 col-md-6 cardDiv">
                        <div class="card">
                        <div class="card-header">
                            <img class="icons" src={Rookie} alt="Rookie icon"></img>
                            <div className='iconText'>
                                Rookie
                            </div>
                        </div>
                        <div class="card-body">
                        <table class="table">
                            <thead style={{borderBottom:'solid 2px #b80000'}}>
                                <tr>
                                <th scope="col">Attribute</th>
                                <th scope="col">Points</th>
                                </tr>
                            </thead>
                            <tbody>
                                {scoreData.map((score, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{score.ROOKIE}</td>
                                            <td style={(score.POINTS_1 > 0)? { backgroundColor:'rgba(158, 222, 175, 0.8)'}: (score.POINTS_1 < 0)?{ backgroundColor:'rgba(219, 158, 156, 0.8)'}: {}}>{score.POINTS_1}</td>
                                        </tr>
                                    )
                                })

                                }
                            </tbody>
                        </table>
                        </div>
                        </div>
                    </div> */}
                    <div class="col-lg-4 col-md-6 cardDiv">
                        <div class="card">
                        <div class="card-header">
                            <img class="icons" src={Defence} alt="Defence icon"></img>
                            <div className='iconText'>
                                Defence
                            </div>
                        </div>
                        <div class="card-body">
                        <table class="table">
                            <thead style={{borderBottom:'solid 2px #b80000'}}>
                                <tr>
                                <th scope="col">Attribute</th>
                                <th scope="col">Points</th>
                                </tr>
                            </thead>
                            <tbody>
                                {scoreData.map((score, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{score.DEFENCE}</td>
                                            <td style={(score.POINTS_2 > 0)? { backgroundColor:'rgba(158, 222, 175, 0.8)'}: (score.POINTS_2 < 0)?{ backgroundColor:'rgba(219, 158, 156, 0.8)'}: {}}>{score.POINTS_2}</td>
                                        </tr>
                                    )
                                })

                                }
                            </tbody>
                        </table>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 cardDiv">
                        <div class="card">
                        <div class="card-header">
                            <img class="icons" src={Goalie} alt="Goalie icon"></img>
                            <div className='iconText'>
                                Goalie
                            </div>
                        </div>
                        <div class="card-body">
                        <table className="table">
                            <thead style={{borderBottom:'solid 2px #b80000'}}>
                                <tr>
                                <th scope="col">Attribute</th>
                                <th scope="col">Points</th>
                                </tr>
                            </thead>
                            <tbody>
                                {scoreData.filter(function(score) {
                                    if (score.GOALIE === null) {
                                        return false; // skip
                                    }
                                    return true;
                                }).map((score, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{score.GOALIE}</td>
                                            <td style={(score.POINTS_3 > 0)? { backgroundColor:'rgba(158, 222, 175, 0.8)'}: (score.POINTS_3 < 0)?{ backgroundColor:'rgba(219, 158, 156, 0.8)'}: {}}>{score.POINTS_3}</td>
                                        </tr>
                                    )
                                })

                                }
                            </tbody>
                        </table>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Scoring;
