import React, {useState, useEffect} from "react";
import "./SponsorsSlideshow.css";
import Carousel from 'react-bootstrap/Carousel';

 const SponsorsSlideshow = () => {

      const [platinumSponsors, SetPlatinumSponsors] = useState([]);
      const [goldSponsors, SetGoldSponsors] = useState([]);
      const [silverSponsors, SetSilverSponsors] = useState([]);
      const [bronzeSponsors, SetBronzeSponsors] = useState([]);
      const [otherSponsors, SetOtherSponsors] = useState([]);

      useEffect(() => {
        fetchPlatinumSponsors();
        fetchGoldSponsors();
        fetchSilverSponsors();
        fetchBronzeSponsors();
        fetchOtherSponsors();
      }, []);

      const fetchPlatinumSponsors = async () => {
        try {
          //change link accordingly
            await fetch(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + '/platinumsponsors/show')
                .then(res => res.json())
                .then((data) => {
                      console.log(data);
                      SetPlatinumSponsors(data);
                    })
        } catch (error) {
            throw error;
        }
      };

      const fetchGoldSponsors = async () => {
        try {
          //change link accordingly
            await fetch(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + '/goldsponsors/show')
                .then(res => res.json())
                .then((data) => {
                      console.log(data);
                      SetGoldSponsors(data);
                    })
        } catch (error) {
            throw error;
        }
      };

      const fetchSilverSponsors = async () => {
        try {
          
          //change link accordingly
            await fetch(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + '/silversponsors/show')
                .then(res => res.json())
                .then((data) => {
                      console.log(data);
                      SetSilverSponsors(data);
                    })
        } catch (error) {
            throw error;
        }
      };

      const fetchBronzeSponsors = async () => {
        try {
          //change link accordingly
            await fetch(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + '/bronzesponsors/show')
                .then(res => res.json())
                .then((data) => {
                      console.log(data);
                      SetBronzeSponsors(data);
                    })
        } catch (error) {
            throw error;
        }
      };

      const fetchOtherSponsors = async () => {
        try {
          //change link accordingly
            await fetch(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + '/othersponsors/show')
                .then(res => res.json())
                .then((data) => {
                        SetOtherSponsors(data);
                        console.log(data);
                    })
        } catch (error) {
            throw error;
        }
      };

  function createCrouselItems(Sponsors_data){
    return(
      Sponsors_data.map(function(sponsor){
        //change link accordingly
        let imageSrc = process.env.REACT_APP_HOCKEYPOOLV2_IMG_URL + sponsor.Image;
        return(
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={imageSrc}
              alt={sponsor.Name}
            />
          </Carousel.Item>  
        );
      })
    );
  }

  return (
    <>
      {platinumSponsors.length > 0 ? (      
        <div>
          <h2 id="silvSponsors"> 2023 Platinum Sponsors</h2>
          <Carousel controls={false} variant="dark" id="sponsorsCarousel-platinum">
            {createCrouselItems(platinumSponsors)}
          </Carousel>
          <br></br>
        </div>
      ) : null}
      {goldSponsors.length > 0 ? (      
        <div>
          <h2> 2023 Gold Sponsors</h2>
          <Carousel controls={false} variant="dark" id="sponsorsCarousel-platinum">
            {createCrouselItems(goldSponsors)}
          </Carousel>
          <br></br>
        </div>
      ) : null}
      {silverSponsors.length > 0 ? (      
        <div>
          <h2> 2023 Silver Sponsors</h2>
          <Carousel controls={false} variant="dark" id="sponsorsCarousel-platinum">
            {createCrouselItems(silverSponsors)}
          </Carousel>
          <br></br>
        </div>
      ) : null}
      {bronzeSponsors.length > 0 ? (      
        <div>
          <h2 id="silvSponsors"> 2023 Bronze Sponsors</h2>
          <Carousel controls={false} variant="dark" id="sponsorsCarousel-platinum">
            {createCrouselItems(bronzeSponsors)}
          </Carousel>
          <br></br>
        </div>
      ) : null}
      {otherSponsors.length > 0 ? (      
        <div>
          <h2 id="silvSponsors"> 2023 Other Sponsors</h2>
          <Carousel controls={false} variant="dark" id="sponsorsCarousel-platinum">
            {createCrouselItems(otherSponsors)}
          </Carousel>
          <br></br>
        </div>
      ) : null}
    </>
  )
}

export default SponsorsSlideshow;