import React, { useContext } from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import { RegistrationContext } from '../../context/RegistrationContext';

const Layout = ({ children, year }) => {
    const registrationPool = useContext(RegistrationContext);

    return (
    <React.Fragment>
        <div style={{minHeight: "100vh"}}>
        {registrationPool.map(pool => {
            return (
                <Navbar registrationIsClosed={pool.isClosed} registrationLink={pool.registrationLink}/>
            )
        })}
        {/* <Navbar/> */}
        <main>{children}</main>
        </div>
        <Footer year={year}/>
    </React.Fragment>
    );
};

export default Layout;
