import React, { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const PrivateRoute = ({ children }) => {

    const currentUser = useContext(AuthContext);
    const [isAuthenticated] = useState(!!sessionStorage.getItem("loggedInUser"))

    if (isAuthenticated || !!currentUser) {
        return children
    }

    return <Navigate to="/login" />
}

export default PrivateRoute;