import React from "react";
import SponsorsSlideshow from "./SponsorsSlideshow";
import './Sponsors.css';

const Sponsors = () => {
  return (
    <React.Fragment>
      <div id="sponsors-bg">
        <br></br>
        <SponsorsSlideshow />
        
        <p>
          <center>
            <p>
              Please contact the Commissioners if you are interested in sponsoring the 2024 pool.
            </p>
            <p>
              Frank:{" "} <a href="mailto:frank.verbari@ontario.ca" className="text-info"><span id="emailColour">frank.verbari@ontario.ca </span></a> 
              or Nino:{" "} <a href="mailto:nino.apostoli@ontario.ca" className="text-info"><span id="emailColour">nino.apostoli@ontario.ca</span> </a>
            </p>
          </center>
        </p>
      </div>
    </React.Fragment>
  );
};

export default Sponsors;
