import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

const UpdateModel = (props) => {
    const [pName, setPrizeName] = useState([]);
    const [imgFile, setImage] = useState([]);

    const updatePrize = async () => {
        // creating a form to hold the name and image
        const formData = new FormData();
        formData.append('prizeId', props.pID);
        formData.append('prizeName', pName);
        formData.append('prizeImg', props.pImg);
        formData.append('image', imgFile);
    
        await axios.post(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + `/updateprize`, formData, {headers: { 'content-type': 'multipart/form-data' }})
            .catch(err => {
                console.error(err);
        })
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="prize-modal-container"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" >
                    Updating Prize "{props.pName}"
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={updatePrize}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-6 prize-img" style={{ padding: '10px', borderRight: '1px solid rgb(201, 196, 196)' }}>
                            <img 
                                className="img-fluid mx-auto d-block"
                                src={props.srcPath + props.pImg}
                                alt={props.pName} 
                            />
                        </div>
                        <div className="col-sm-6 prize-name">
                            <p>ID: {props.pID}</p>
                            <p>Is Claimed: {props.pClaimed}</p>
                            <label htmlFor="prizeName">Prize Name:</label>
                            <input
                                name="prizeName"
                                type="text"
                                className="form-control"
                                placeholder={props.pName}
                                onChange={(e) => setPrizeName(e.target.value)}
                            />
                            <label htmlFor='prizeImage'>Prize Image:</label>
                            <input
                                name='prizeImage'
                                type='file'
                                className='form-control'
                                onChange={(e) => setImage(e.target.files[0])}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button class='btn btn-success'>Update</button>
                    {/* <button onClick={props.onHide} className='btn btn-danger'>Cancel</button> */}
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default UpdateModel;