import React, { useEffect, useState } from 'react';
import './Admin.css';
import { useForm } from '../../forms/useForm';
import { useNavigate } from 'react-router-dom';
import firebaseAuth from '../../assets/config/FirebaseAuth';
import * as XLSX from "xlsx";
import PrizesUI from '../prizes-UI/PrizesUI';
 
 
const Admin = (props) => {
 
    const navigate = useNavigate();
    const { values, handleChange } = useForm({
        registerLink: props.regristrationLink,
        isClosed: props.registrationIsClosed
    });
 
    const [showSuccess, setSuccess] = useState(false);
    const [showFailure, setFailure] = useState(false);
    const [failureCause, setFailureCause] = useState("");
    const [excelData, setexcelData] = useState([[],[],[]]);
    const [emailSuccess, setEmailSucess] = useState(false);
 
 
 
    useEffect(() => {
        let authorized = sessionStorage.getItem("LoggedInUser");
        if (authorized) {
            navigate('/admin');
        }
        if (!authorized) {
            navigate('/login');
        }
    }, [navigate]);
 
    const changeRegisterStatus = async (e) => {
        try {
            e.preventDefault();
            const data = {
                updateType: "registerStatus",
                value: !values.isClosed
            };
            await fetch(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + `/update-pool`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(res => {
                    if (res.data = true) {
                        window.location.reload(true);
                    } else {
                        alert("Failed to update registration status");
                    }
                })
        } catch (error) {
            throw error;
        }
    }

    const changeRegisterLink = async (e) => {
        try {
            e.preventDefault();
            const data = {
                updateType: "registerLink",
                value: values.registerLink
            };
            await fetch(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + `/update-pool`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(res => {
                    if (res.data = true) {
                        window.location.reload(true);
                    } else {
                        alert("Failed to update registration link");
                    }
                })
        } catch (error) {
            throw error;
        }
    }

    const handleLogout = () => {
        sessionStorage.removeItem("LoggedInUser");
        firebaseAuth.auth().signOut().then(function() {
          // Sign-out successful.
          console.log('logged out');
        }).catch(function(error) {
          console.error(error);
        });
        window.location.reload(false);
    }
 
    const onExcelChange = (e) => {
        const [file] = e.target.files;
        const reader = new FileReader();
   
        reader.onload = (evt) => {
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          console.log(data);
       
          try {
                if(ws.A1.h === "Rank" && (ws.B1.h === "Full Name" ||ws.B1.h === "FullName") && ws.C1.h === "Email"){
                //alert("Updated rankings successfully")
                setSuccess(true);
                setFailure(false);
                setexcelData(data);
                }else{
                    setSuccess(false);
                    setFailureCause("Data formatted wrong");
                    setFailure(true);
                    //alert("Data formatted wrong")
                }
            } catch (error) {
                throw error;
            }
        };
        reader.readAsBinaryString(file);
    };
 
    const RenderExcel = ({data}) => (
        <div>
            <div id="successMessage">
            <h4>Imported Data</h4>
            </div>
            <table className="excelTable">
                <tbody>
                                {data.map((element, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{element[0]}</td>
                                            <td>{element[1]}</td>
                                            <td>{element[2]}</td>
                                        </tr>
                                    )
                                })
 
                                }
                            </tbody>
            </table>
 
            <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#submissionModal">
            Submit
            </button>
        </div>
    );
 
    const handleEmail = async () => {
        try{
            const winners = excelData;
            winners.sort(function(a, b) {
                return a[0] - b[0];
            });
            winners.shift();
            const  winnerEmails = winners.map(w => w[2]);
            const  winnerNames = winners.map(w => w[1]);
 
            const data = {
                postorder: 0,
                postemails: winnerEmails,
                postnames: winnerNames
            }
 
            fetch(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + `/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }).then(res => {
                console.log(res)
                if (res.status==200) {
                    setEmailSucess(true);
                } 
                else {
                    setSuccess(false);
                    setFailureCause("Failed to update rankings on server");
                    setFailure(true);
                }
        })
        } catch (error){
            throw error;
        }
    }
   
    return (
        <>
            <div id="admin-container">
                <div className="heading">
                    <h1 className="text-center">Admin Dashboard</h1>
                </div>
                <div className="logout-btn">
                    <button
                        id='logoutBtn'
                        className='btn btn-danger logout'
                        type='button'
                        onClick={handleLogout}
                    >
                        Logout
                    </button>
                </div>
                <h2>RANKING THE WINNERS</h2>
                <p className="text-center">Now that the playoffs have came to an end, we must distribute the prizes to the winners</p>
                <div id="upload-winners">
                    <h3>Upload winners in .xlsx format</h3>
                    <p>Columns required in order: Rank, Full Name, Email</p>
                    <label className='custom-file-upload'>
                        <input type="file" accept='.xlsx' onChange={onExcelChange} />
                    </label>
                    <br></br>
                    {showFailure?
                    <div class="alert alert-danger" role="alert">
                      Failed: {failureCause}
                    </div>
                   
                    : ""}
                    {emailSuccess?
                    <div class="alert alert-success" role="alert">
                        Email System Running!
                    </div>
                    :""}
                    {showSuccess ? <RenderExcel data={excelData}/> : null}
                   
 
                    <div class="modal fade" id="submissionModal" tabindex="-1" role="dialog" aria-labelledby="submissionModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="submissionModalLabel">Submission confimation</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                Are you sure? Ranking data seen in table will be uploaded to the server and the email process will begin.
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                                <button onClick={handleEmail} type="button" data-dismiss="modal" class="btn btn-primary">Yes</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="shadowContainer">
                    <div></div>
                </div>
                <div class='row'>
                    <div class='col-lg-12'>
                        <div id="registration">
                            <h1>Registration</h1>
                            <p>Change the registration link and/or Enable or Disable the link</p>
                            <form onSubmit={changeRegisterLink}>
                                <label style={{margin: '5px'}} htmlFor="registerLink">Registration Link:</label>
                                <div className="input-group link-field">
                                    <input
                                        name="registerLink"
                                        type="text"
                                        id="registerLink"
                                        className="form-control"
                                        placeholder="Enter Registration Link...."
                                        onChange={handleChange}
                                        value={values.registerLink}
                                        required
                                    />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="submit">Change</button>
                                    </div>
                                </div>
                                <div className="input-group register-btn" >
                                    <strong style={{margin: '5px'}}>Registration Status:</strong>
                                </div>
                                <button
                                        id="statusButton"
                                        className={values.isClosed ? "btn btn-danger" : "btn btn-success"}
                                        type="button"
                                        onClick={changeRegisterStatus}
                                    >
                                        {values.isClosed ? 'Disable' : 'Enable'}
                                    </button>
                            </form>
                        </div>
                    </div>
                    
                    <div class='col-lg-12' className='prize-ui'>
                        <PrizesUI />
                    </div>
                </div>
            </div>
        </>
    );
};
 
export default Admin;   