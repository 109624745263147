import React from "react";
import Modal from 'react-bootstrap/Modal';

const DeleteModal = (props) => {
    const deletePrize = async () => {
        try {
            const data = {
                pID: props.pID,
                pImg: props.pImg
            };
            await fetch(process.env.REACT_APP_HOCKEYPOOLV2_API_URL + `/deleteprize`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(res => {

                    if (res.ok === true) {
                        window.location.reload(true);
                    } 
                    else {
                        alert("Failed to update registration status");
                    }
                })
        } catch (error) {
            throw error;
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="prize-modal-container"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" >
                    Are you sure you want to delete?
                </Modal.Title>
            </Modal.Header>
            <form>
                <Modal.Body>
                    <p>ID: {props.pID}</p>
                    <p>Name: {props.pName}</p>
                    <p>Is Claimed: {props.pClaimed}</p>
                    <img 
                        className="img-fluid mx-auto d-block"
                        src={props.srcPath + props.pImg}
                        alt={props.pName} 
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={deletePrize} className='btn btn-danger'>Delete</button>
                    <button onClick={props.onHide} className='btn btn-secondary'>Cancel</button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default DeleteModal;