import React from "react";
import "./About.css";

const About = () => {
  return (
    <React.Fragment>
      <section className="content-section text-center" id="about">
        <div className="container">
          <div className="content-section-heading font-weight-bold">
            <br />

            {/* <h1 className="mb-5 display-2 about">About Us</h1> */}
            <div className="heading"><h1>About Us</h1></div><br />
            <div class="paragraphs">
              <p>
              Federated Health Charities is one of the two workplace charitable campaigns of the Ontario Public Services (OPS). 
              For over 40 years now, these campaigns have raised over $54 million for Ontario’s major health charities to support 21+ 
              health charities through payroll deductions, donations and special events. This is an unprecedented achievement for a charity 
              that exists within a single workplace.
              </p>

              <p>
              Some of the notable charities include ALS Society of Canada, Alzheimer Society of Canada, 
              Canadian Cancer Society – Ontario Chapter, Canadian Liver Foundation, Heart and Stroke, Osteoporosis Canada, etc. 
              This year 3 new charities are being welcomed: Autism Ontario, Parkinson Canada and MS Society of Canada.
              </p>

              <p>
              A popular event in support of this charity campaign is this one, the Annual Federated Health Hockey Playoff Pool. 
              Now in its 12th year, this event has raised over $15,000 towards the cause. We owe thanks to poolies like all of you; 
              without your participation this event could not be such a great success!
              </p>

              <p>
              This event has proven to be a fun-filled and exciting opportunity to claim bragging rights over your colleagues. 
              Each year, the participation rate has increased; last year we set a record enrollment with 123 teams registered.
              </p>

              <p>Truly a great accomplishment!</p>
              <p>Regards,</p>
              <p>Commissioners Nino Apostoli and Frank Verbari!</p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default About;
