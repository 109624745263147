import React from "react";
import "./homepageContent.css";

const HomepageContent = () => {
    return (
        <React.Fragment>
            <section class="content-section bg-light text-center" id="homepageContent">
                <div class="container">
                    <div class="content-section-heading ">
                        <h1>
                            <b>WELCOME TO THE ITS FEDERATED HEALTH HOCKEY PLAYOFF POOL!</b>
                        </h1>
                        <br />

                        <h2>Hey Poolies…</h2><br></br>

                        <p>It’s that time of year when the couch potato managers battle to see who will emerge victorious in the <br></br><strong><span class="annualIts">Annual ITS Federated Health NHL Playoff Hockey Pool</span></strong>.</p>

                        <p>We all know that with the arrival of spring comes the arrival of Federated Health Charities Campaign!</p>

                        <p>Just ask any of the veteran hardcore Poolies about the level of intensity…the gamesmanship, and the excitement that comes with this pool. Some will pour their heart and soul into this epic office battle, while others will sweat it out hoping to gain momentum later in the race. If you are a rookie, let us tell you that
                            hockey pools rarely work out as planned. In a playoff pool, very much like the NHL playoffs themselves, it’s a whole new game! It’s a big guessing game…not only do you have to guess which players put points on the board each and every game but more importantly, you have to guess the teams that will have a deep playoff run!
                        </p>

                        <p>From a Canadiana perspective, it’s clear that 2 distinct divisions are emerging. The ‘haves’ are the surprising Vancouver Canucks, who were playing at a .660 clip; the Winnipeg Jets, a solid third in the Central Division; and Toronto Maple Leafs, playing .636 hockey in a tough Atlantic Division. The ‘have-nots’ are three of the usual suspects — Montreal, Ottawa, and Calgary — and one surprise, the Edmonton Oilers, who were one of the pre-season favourites to contend for the Stanley Cup and went from the ‘have-nots’ to the ‘haves’ in the matter of 16 games!</p>

                        <p>For the 56th year in a row Toronto Maple Leaf fans are hopeful…Just once before we die tears Commish Nino…will they get to bear witness to their beloved team hoisting the Stanley Cup?  Per favore, just once before we die!  The Boston Bruins have been exceptional all year round…Will Brad Marchand and his Bruins once again suffer the much feared “Curse of the President’s Cup”, or will that be the fate of the Canucks this time around?
                        </p>

                        <p>It’s almost like the Western Conference teams have been playing roulette as of late…Will this be another year that a Western Conference Team upsets the East?</p>

                        <p>Get ready to enter the Playoff time warp - full of excitement, drama, frustration, and agony – for all who dare to play!</p>

                        <p>It’s time to share your special recipe full of physicality, grit, speed, and finesse and bake your “ice” crème de la crème!</p>

                        <p>Your Commissioners, Frank and Nino, have witnessed far too often that poolies forget the basics. They have seen the hard-core hockey fans drown in statistics -- plus-minus, home winning percentages, road winning percentages and countless other numbers which they know won’t even be relevant. Take it with a “grain of salt” if you will, but your commissioners suggest you not worry about all the countless variables that could come into play and just try and have fun!   It’s NOT such a tall order!   16 teams!!   Over 350 players!!!    As Jason Kelce of NFL fame always says, “just clean the pile”!    Pick a few teams to cover your IF-THEN-ELSE bets and you’’ be all set!
                        </p>

                        <p>
                            As the weather starts to warm up, stay focused on the temperatures in Stanley Cup Country. Pull out your thermometer, open your mouth and say,  <strong>“Game On”!</strong>
                        </p>

                        <p>
                            Commissioners, errr upcoming Color commentators, Nino and Frank want you to put your hockey heads on and raise some money for a great cause.
                            <br></br>So, <strong>let’s Play it Forward!</strong>
                        </p>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export default HomepageContent;
